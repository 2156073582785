import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "authorNames", 
    // "backCoverDisplayImage",
    // "backCoverForm",
    // "blurbField",
    "bookCoverDisplayContainer", 
    "bookCoverDisplayImage",
    "coverBackgroundField", 
    "coverImageField", 
    "coverStyleField", 
    "frontCoverForm",
    "frontCoverFormSubmit",
    "subTitleField", 
    "titleField"];

  timeoutId = null; // Property to keep track of the timeout

  connect() {
    const csrfToken = document.querySelector("meta[name='csrf-token']").content;
    this.headers = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": csrfToken
    };

    // Attach the keyup event listener to the titleField target
    this.toggleSubtitleVisibility();
    // this.authorNamesTarget.addEventListener('keyup', (event) => this.handleChangeFront(event));
    // this.blurbFieldTarget.addEventListener('keyup', (event) => this.handleChangeBack(event));
    // this.coverBackgroundFieldTarget.addEventListener('change', () => this.handleChangeFront());
    // this.coverImageFieldTarget.addEventListener('change', () => this.handleChangeFront());
    // this.coverStyleFieldTarget.addEventListener('change', () => this.handleChangeFront());
    // this.titleFieldTarget.addEventListener('keyup', (event) => this.handleChangeFront(event));
    // this.subTitleFieldTarget.addEventListener('keyup', (event) => this.handleChangeFront(event));
    this.frontCoverFormSubmitTarget.addEventListener('click', (event) => this.submitFrontCoverForm(event));
  }

  handleChangeBoth() {
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.submitFrontCoverForm();

      this.timeoutId = setTimeout(() => {
        this.submitBackCoverForm();
      }, 5000);
      
    }, 2000);
  }

  // ================================================================================
  // FRONT COVER =====================================================================
  // ================================================================================
  handleChangeFront() {
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.submitFrontCoverForm();
    }, 4000);
  }

  submitFrontCoverForm() {
    const coverBackgroundSelect = this.frontCoverFormTarget.querySelector("[data-books-cover-target='coverBackgroundField']");
    if (!coverBackgroundSelect.value || coverBackgroundSelect.value === "-- select --") {
        return;
    }

    const formData = new FormData(this.frontCoverFormTarget);
    this.toggle_form_front(true);

    this.bookCoverDisplayImageTarget.style.opacity = 0.5;

    const bookCoverDisplayLoading = document.getElementById("book-cover-display-loading");
    bookCoverDisplayLoading.classList.remove("visually-hidden");

    fetch(this.frontCoverFormTarget.action, {
      method: 'POST',
      headers: this.headers,
      body: formData,
      credentials: 'include'
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error("Network response was not ok.");
    })
    .then((data) => {
      this.bookCoverDisplayImageTarget.setAttribute("src", data.url);

      setTimeout(() => {
        bookCoverDisplayLoading.classList.add("visually-hidden");
        this.bookCoverDisplayImageTarget.style.opacity = 1;
        this.toggle_form_front(false)
      }, 6000);
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    })
  }

  toggleSubtitleVisibility() {
    // Retrieve the selected value from the coverStyleField target
    const selectedCoverStyle = this.coverStyleFieldTarget.value;
  
    // Determine the elements to show or hide based on selection
    const subtitleField = this.subTitleFieldTarget.closest('.form-floating');
  
    // Check if the selected cover style is 'simple_text' and show/hide accordingly
    if (selectedCoverStyle === 'simple_text') {
      subtitleField.style.display = 'none';
    } else {
      subtitleField.style.display = 'block';
    }
  }  

  // ================================================================================
  // BACK COVER =====================================================================
  // ================================================================================
  handleChangeBack() {
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.submitBackCoverForm();
    }, 4000);
  }

  submitBackCoverForm() {
    const coverBackgroundSelect = this.frontCoverFormTarget.querySelector("[data-books-cover-target='coverBackgroundField']");
    if (!coverBackgroundSelect.value || coverBackgroundSelect.value === "-- select --") {
        return;
    }
    
    const formData = new FormData(this.backCoverFormTarget);
    this.toggle_form_back(true);

    this.backCoverDisplayImageTarget.style.opacity = 0.5;

    const backCoverDisplayLoading = document.getElementById("back-cover-display-loading");
    backCoverDisplayLoading.classList.remove("visually-hidden");

    fetch(this.backCoverFormTarget.action, {
      method: 'POST',
      headers: this.headers,
      body: formData,
      credentials: 'include'
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error("Network response was not ok.");
    })
    .then((data) => {
      this.backCoverDisplayImageTarget.setAttribute("src", data.url);

      setTimeout(() => {
        backCoverDisplayLoading.classList.add("visually-hidden");
        this.backCoverDisplayImageTarget.style.opacity = 1;
        this.toggle_form_back(false)
      }, 5000);
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    })
  }

  toggle_form_front(value) {
    Array.from(this.frontCoverFormTarget.elements).forEach(element => {
      element.disabled = value;
    });
  }

  toggle_form_back(value) {
    Array.from(this.backCoverFormTarget.elements).forEach(element => {
      element.disabled = value;
    });
  }
}