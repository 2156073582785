import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="voice-recorder"
export default class extends Controller {
  static values = {
    authorCanUpdate: Boolean,
  };

  static targets = [
    "startButton",
    "cancelButton",
    "stopButton", 
    "responseArea",
    "audioVisualizer",
    "promptText",
    "countdown",
  ];

  connect() {
    const csrfToken = document.querySelector("meta[name='csrf-token']").content

    this.headers = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": csrfToken
    }

    if (this.authorCanUpdateValue) {
      this.modal = new Modal(document.getElementById('voiceRecorderConfirmationModal'));

      this.canvasContext = this.audioVisualizerTarget.getContext("2d");

      this.idleAnimation();
    }

    this.isCancelled = false;
  }

  setupMediaRecorder(stream) {
    this.mediaRecorder = new MediaRecorder(stream);
    this.audioChunks = [];

    this.mediaRecorder.addEventListener("dataavailable", event => {
      this.audioChunks.push(event.data);
    });

    this.mediaRecorder.addEventListener("stop", () => {
      if (!this.isCancelled) {
        const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
        this.uploadAudio(audioBlob);
      }
    });
  }

  startRecording() {
    this.modal.show();
  }

  actuallyStartRecording() {
    this.isCancelled = false;

    if (!this.audioContext) {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = 2048;
      this.bufferLength = this.analyser.frequencyBinCount;
      this.dataArray = new Uint8Array(this.bufferLength);
    }

    this.cancelButtonTarget.innerHTML = "Cancel";

    cancelAnimationFrame(this.idleAnimationFrameRequest);

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        this.stream = stream;

        this.startCountdown(10 * 60);

        this.setupMediaRecorder(stream);

        const source = this.audioContext.createMediaStreamSource(stream);
        source.connect(this.analyser);
        this.drawVisualizer();

        this.mediaRecorder.start();

        this.stopButtonTarget.classList.remove("d-none");
        this.startButtonTarget.classList.add("d-none");
      })
      .catch(
        error => console.error("Error accessing the microphone:", error)
      );
  }

  cancelRecording() {
    this.isCancelled = true;

    this.cancelButtonTarget.innerHTML = "Close";

    if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
      this.mediaRecorder.stop();
    }
  
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  
    clearInterval(this.countdownInterval);
  
    this.countdownTarget.textContent = "Time left in session: 10:00";
  
    this.stopButtonTarget.classList.add("d-none");
    this.startButtonTarget.classList.remove("d-none");
    
    cancelAnimationFrame(this.animationFrameRequest);
    this.idleAnimation();
  
    this.audioChunks = [];
  
    this.modal.hide();
  }

  stopRecording() {
    this.isCancelled = false;

    this.cancelButtonTarget.innerHTML = "Close";
    
    this.mediaRecorder.stop();
    this.stream.getTracks().forEach(track => track.stop());

    clearInterval(this.countdownInterval);
    this.countdownTarget.textContent = "Time left in session: 10:00";

    this.stopButtonTarget.classList.add("d-none");
    this.startButtonTarget.classList.remove("d-none");

    cancelAnimationFrame(this.animationFrameRequest);
    this.idleAnimation();
  }

  startCountdown(duration) {
    let remaining = duration;
    this.updateCountdown(remaining);
    
    this.countdownInterval = setInterval(() => {
      remaining -= 1;
      this.updateCountdown(remaining);
  
      if (remaining <= 0) {
        clearInterval(this.countdownInterval);
        this.stopRecording();
      }
    }, 1000);
  }

  updateCountdown(seconds) {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    const formattedTime = `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    this.countdownTarget.textContent = `Time left in session: ${formattedTime}`;
  }

  uploadAudio(audioBlob) {
    const formData = new FormData();
    formData.append("audio", audioBlob);

    fetch("/ai/transcriptions", {
      method: "POST",
      body: formData,
      headers: this.headers,
      credentials: "same-origin",
    })
    .then(
      response => response.json()
    )
    .then(data => {
      console.log(data);

      this.responseAreaTarget.value += data.transcription.text;

      const event = new Event('input', { bubbles: true, cancelable: true });

      this.responseAreaTarget.dispatchEvent(event);

      this.modal.hide();
    })
    .catch(
      error => console.error(error)
    );
  }

  idleAnimation() {
    const width = this.audioVisualizerTarget.width;
    const height = this.audioVisualizerTarget.height;
    const centerY = height / 2;
    const amplitude = 0;
  
    this.canvasContext.clearRect(0, 0, width, height);
    this.canvasContext.strokeStyle = 'rgb(255, 255, 255)';
    this.canvasContext.lineWidth = 1;
  
    this.canvasContext.beginPath();
    this.canvasContext.moveTo(0, centerY);
  
    for (let i = 0; i < width; i++) {
      const y = centerY + amplitude * Math.sin(i * 0.01);
      this.canvasContext.lineTo(i, y);
    }
  
    this.canvasContext.stroke();
  
    this.idleAnimationFrameRequest = requestAnimationFrame(() => this.idleAnimation());
  }

  drawVisualizer() {
    requestAnimationFrame(() => this.drawVisualizer());

    this.canvasContext.clearRect(0, 0, this.audioVisualizerTarget.width, this.audioVisualizerTarget.height);

    this.analyser.getByteTimeDomainData(this.dataArray);

    this.canvasContext.lineWidth = 1;
    this.canvasContext.strokeStyle = 'rgb(255, 255, 255)';

    this.canvasContext.beginPath();

    var sliceWidth = this.audioVisualizerTarget.width * 1.0 / this.bufferLength;
    var x = 0;

    for(var i = 0; i < this.bufferLength; i++) {
   
      var v = this.dataArray[i] / 128.0;
      var y = v * this.audioVisualizerTarget.height/2;

      if(i === 0) {
        this.canvasContext.moveTo(x, y);
      } else {
        this.canvasContext.lineTo(x, y);
      }

      x += sliceWidth;
    }

    this.canvasContext.lineTo(this.audioVisualizerTarget.width, this.audioVisualizerTarget.height/2);
    this.canvasContext.stroke();
  }
}

