import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="opal-demo"
export default class extends Controller {
  static targets = [
    "animationContainer",
    "getStartedButtonContainer",
    "startButtonContainer",
    "stateOfOpalText",
  ]

  connect() {
    this.interactionCount = 0;

    this.#setupLocalSpeechRecognition();
  }

  async startSession() {
    try {
      // This is to front load the permission request for the microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      stream.getTracks().forEach(track => track.stop())
    } catch (error) {
      alert("Either your browser does not support audio recording, or access to the microphone was denied.")

      return;
    }

    this.startButtonContainerTarget.classList.add('d-none')
    this.animationContainerTarget.classList.remove('d-none')
    this.stateOfOpalTextTarget.innerText = 'Thinking'

    await this.#playOpalMessage('/sounds_acknowledging/1.mp3')

    this.#handleSession()
  }

  async #handleSession() {
    // Greet user
    let urlToAudioFile = await this.#sendMessage(null, `start at ${new Date()}`)
    this.stateOfOpalTextTarget.innerText = 'Speaking'
    await this.#playOpalMessage(urlToAudioFile)

    while(this.interactionCount < 3) {
      // Listen to user
      if (this.recognition) {this.recognition.start()};
      this.stateOfOpalTextTarget.innerText = 'Listening'
      let audioBlob = await this.#startRecordingMessage();
      if (this.recognition) {this.recognition.stop()};

      // Respond to user
      this.stateOfOpalTextTarget.innerText = 'Thinking'
      if (this.interactionCount < 2) {
        urlToAudioFile = await this.#sendMessage(audioBlob);
      } else {
        urlToAudioFile = await this.#sendMessage(audioBlob, 'end interview');
      }
      this.stateOfOpalTextTarget.innerText = 'Speaking'
      await this.#playOpalMessage(urlToAudioFile);

      this.interactionCount++;
    }

    this.animationContainerTarget.classList.add('d-none');
    this.getStartedButtonContainerTarget.classList.remove('d-none');

    this.#stopSession();
  }

  #stopSession() {
    if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
      this.mediaRecorder.stop();
    }

    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }

    if (this.advanceTimeout) {
      clearTimeout(this.advanceTimeout);
      this.advanceTimeout = null;
    }
  }

  #playOpalMessage(url) {
    return new Promise((resolve, reject) => {
      if (!this.opalMessage) {
        this.opalMessage = new Audio(url);
      } else {
        this.opalMessage.src = url;
        this.opalMessage.load();
      }

      this.opalMessage.onplay = () => {
        console.log("Opal started speaking");
      };

      this.opalMessage.onended = () => {
        console.log("Opal stopped speaking");

        resolve();
      };

      this.opalMessage.onerror = (e) => {
        const errorMEssage = "Opal failed to speak";

        console.error(errorMEssage, e);

        reject(new Error(errorMEssage));
      };

      this.opalMessage
        .play()
        .catch(err => {
          console.error("Error when Opal attempted to speak", err);

          reject(err);
        });
    });
  }

  #sendMessage(audioBlob = null, text = null) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      if (audioBlob) {
        formData.append("message[audio]", audioBlob);
      }

      if (text) {
        formData.append("message[text]", text);
      }
      
      const request = new FetchRequest('post', '/ai/biographer_demos',
        {
          body: formData
        }
      )

      request.perform()
        .then(response => {
          if(response.ok) {
            console.log(response.response)

            return response.response.json();
          } else {
            throw new Error('Failed to get greeting audio file')
          }
        })
        .then(data => {
          console.log(data.url)

          resolve(data.url);
        })
        .catch(error => {
          console.error("Error fetching or playing the audio.", error);
          reject(error)
        })
    })
  }

  #startRecordingMessage() {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.stream = stream
          this.mediaRecorder = new MediaRecorder(stream);
          this.audioChunks = [];
      
          this.mediaRecorder.addEventListener("dataavailable", event => {
            this.audioChunks.push(event.data);
          });

          this.mediaRecorder.start();

          this.mediaRecorder.addEventListener('stop', () => {
            const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" })

            resolve(audioBlob)
          })
        })
        .catch(error => {
          console.error("Error accessing the microphone:", error)

          reject(error)
        })
    })
  }

  #stopRecordingMessage() {    
    console.log("Stopping recording...");

    this.mediaRecorder.stop();
    this.stream.getTracks().forEach(track => track.stop());
  }

  // ------------------------------------------------------------------------------------------------------------------
  // Local speech recognition
  // ------------------------------------------------------------------------------------------------------------------
  #setupLocalSpeechRecognition() {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

    this.recognition = new SpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.lang = 'en-US';
    this.recognition.interimResults = true;

    this.recognition.onresult = () => {
      console.log("Speech detected...");

      if (this.advanceTimeout) {
        clearTimeout(this.advanceTimeout);
      }

      this.advanceTimeout = setTimeout(() => {
        console.log("Silence detected...");
        
        this.#stopRecordingMessage();
      }, 3000);
    };

    this.recognition.onend = () => {
      console.log("Speech recognition ended...");
    };

    this.recognition.onerror = (event) => {
      console.error("Speech recognition error: ", event.error);
    };
  }
}
