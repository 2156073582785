import { Controller } from "@hotwired/stimulus";
import html2canvas from "html2canvas";
import { updatePromptIcons } from "../prompt_icons";

export default class extends Controller {
  connect() {
    const canvasTarget = document.getElementById("canvas-target");
    const imageEditModal = document.getElementById('imageEditModal');

    imageEditModal.addEventListener('shown.bs.modal', adjustCanvasStyles.bind(this));

    this.isDragging = false;
    this.startX = 0;
    this.startY = 0;

    canvasTarget.addEventListener('mousedown', this.startImageDrag.bind(this));
    canvasTarget.addEventListener('mouseup', this.stopImageDrag.bind(this));
    canvasTarget.addEventListener('mousemove', this.onImageDrag.bind(this));

    html2canvas(canvasTarget, { allowTaint: true, useCORS: true }).then(function(canvas) {
      canvasTarget.appendChild(canvas);
    });
  }

  new() {
    const fileInput = document.getElementById('file-selector');
    fileInput.addEventListener('change', this.updateImage.bind(this));
    fileInput.click();
  }

  updateImage(event) {
    event.preventDefault();

    const canvasTarget = document.getElementById("canvas-target");
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        canvasTarget.querySelector('img').src = e.target.result;
      };

      reader.readAsDataURL(file);
    }
  }

  startImageDrag(event) {
    event.preventDefault();

    const canvasTarget = document.getElementById("canvas-target");

    this.isDragging = true;
    this.startX = event.clientX - canvasTarget.getBoundingClientRect().left - canvasTarget.querySelector('img').offsetLeft;
    this.startY = event.clientY - canvasTarget.getBoundingClientRect().top - canvasTarget.querySelector('img').offsetTop;

    canvasTarget.style.cursor = 'grabbing';
  }

  stopImageDrag(event) {
    event.preventDefault();

    const canvasTarget = document.getElementById("canvas-target");

    this.isDragging = false;
    canvasTarget.style.cursor = 'grab';
  }

  onImageDrag(event) {
    event.preventDefault();

    const canvasTarget = document.getElementById("canvas-target");
    const imgElement = canvasTarget.querySelector('img');

    if (!this.isDragging) return;

    let y = event.clientY - canvasTarget.getBoundingClientRect().top - this.startY;

    // Get the boundaries
    const minY = 0;  // Top boundary
    const maxY = canvasTarget.clientHeight - imgElement.clientHeight;  // Bottom boundary

    // Apply constraints to y
    if (y < maxY) y = maxY;  // Don't drag the image up too much
    if (y > minY) y = minY;  // Don't drag the image down too much

    imgElement.style.top = `${y}px`;
  }

  save(event) {
    event.preventDefault();

    const canvasTarget = document.getElementById("canvas-target");
    html2canvas(canvasTarget, { allowTaint: true, useCORS: true }).then(function(canvas) {
      var screenShot = canvas.toDataURL('image/jpeg');
      postToAPI(screenShot);
    });
  }

  destroy(event) {
    event.preventDefault();

    const bookContentId = document.getElementById("canvas-target").dataset.bookContentId;
    
    fetch(`/book_contents/${bookContentId}/destroy_image`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({
        id: bookContentId
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      };
    }).then((data) => {
      const canvasTargetImage = document.getElementById("canvas-target-image");
      canvasTargetImage.src = "";
    });
  }
}

function postToAPI(screenShot) {
  const bookContentId = document.getElementById("canvas-target").dataset.bookContentId;

  // Make button saving state
  const button = document.getElementById("image-edit-button");
  const spinnerIcon = document.getElementsByClassName("image-button-spinner-icon")[0];
  const buttonIcon = document.getElementsByClassName("image-button-icon")[0];
  button.disabled = true;
  spinnerIcon.classList.remove("visually-hidden");
  buttonIcon.classList.add("visually-hidden");

  fetch(`/book_contents/${bookContentId}/crop_image`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    body: JSON.stringify({
      image: screenShot
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Network response was not ok.");
    };
  }).then((data) => {
    const imageUrl = data.image.url;
    const canvasTargetImage = document.getElementById("canvas-target-image")
    canvasTargetImage.src = imageUrl;
    canvasTargetImage.style.top = "0px"; // This moves it back into position

   // Return button to saved state
    button.disabled = false;
    spinnerIcon.classList.add("visually-hidden");
    buttonIcon.classList.remove("visually-hidden");

    // Update image icon
    updatePromptIcons(data);
  });
}

function adjustCanvasStyles() {
  const canvas = document.getElementById('canvas-target');
  const width = canvas.offsetWidth; // current width of the canvas
  const newHeight = 0.5629 * width;  // 57% of the width
  canvas.style.height = `${newHeight}px`; // set the new height
}