import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";
import { createAlert } from "../async_alerts";

// Connects to data-controller="books-publish"
export default class extends Controller {
  static values = {
    bookId: Number,
  };

  prepareContents(event) {
    const request = new FetchRequest('patch', `/books/${this.bookIdValue}/prepare_contents`, {
      body: JSON.stringify(
        {
          id: this.bookIdValue,
        }
      )
    })

    request.perform()
      .then((response) => {
        if (response.redirected) {
          window.location.href = response.response.url;
        }

        if (response.ok) {
          return response.response.json();
        } else {
          throw new Error("Network response was not ok.");
        };
      })
      .then((data) => {
        document.getElementById("loading-spinner-container").classList.remove("d-none");
        document.getElementById("books-show").classList.add("d-none");

        setTimeout(() => {
          window.location.href = `/books/${this.bookIdValue}/preview`;
        }, 120000);
      })
      .catch((error) => {
        createAlert(
          error, {
            headerText: "Error",
            iconClass: "bi-exclamation-circle",
            textColorClass: "text-danger"
          }
        );
      });
  }
}
