import { Controller } from "@hotwired/stimulus"
import JSConfetti from 'js-confetti'


// Connects to data-controller="books-publish-pending"
export default class extends Controller {
  connect() {
    const jsConfetti = new JSConfetti();

    jsConfetti.addConfetti({
      confettiNumber: 500,
      emojis: ['📕', '🥳', '📗', '🎉', '📘', '🎊', '📙']
    });
  }
}
