import { Toast } from 'bootstrap';

export function createAlert(message, options = {}) {
  // Set default values
  const header = options.headerText || "Alert!";
  const icon = options.iconClass || "bi-info-circle";
  const textColor = options.textColorClass || "text-dark";

  // Create toast
  const toast = document.createElement("div");
  toast.classList.add("toast", "m-0", "mt-3");
  toast.setAttribute("role", "alert");
  toast.setAttribute("aria-live", "assertive");
  toast.setAttribute("aria-atomic", "true");
  toast.innerHTML = `
    <div class="toast-header">
      <i class="bi ${icon} me-2 ${textColor}"></i>
      <strong class="me-auto">${header}</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      ${message}
    </div>
  `;

  // Add toast to DOM
  const toastContainer = document.getElementById("toast-container");
  toastContainer.appendChild(toast);

  // Initialize toast
  const toastInstance = new Toast(toast);

  // Show toast
  toastInstance.show();

  // Remove toast from DOM after it's automatically hidden
  toast.addEventListener('hidden.bs.toast', () => {
    toast.remove();
  })
}