import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar"
export default class extends Controller {
  static targets = ['link'];

  connect() {
    this.setActiveLink();
  }

  setActiveLink() {
    const currentPath = window.location.pathname;

    this.linkTargets.forEach((link) => {
      const linkPath = link.getAttribute('href');
      
      if (currentPath === linkPath) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    });
  }
}
