import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['link'];

  connect() {
    this.createCheckout();
  }

  createCheckout() {
    fetch("/checkout", {
      method: "POST",
      headers: { "Content-Type": "application/json" }
    });
  }
}