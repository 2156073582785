import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const countrySelects = document.querySelectorAll('.country-select');

    countrySelects.forEach(selectElement => {
      selectElement.addEventListener('change', () => {
        onCountryChange(selectElement);
      });
    });
  }
}

function onCountryChange(selectElement) {
  const selectedCountry = selectElement.value;
  const regionSelect    = selectElement.closest('.container').querySelector('.region-select');

  fetch(`/cart/regions_for_country?country_code=${selectedCountry}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => response.json())
  .then(data => {
    regionSelect.innerHTML = '';

    const blankOption = document.createElement('option');
    blankOption.textContent = "";
    regionSelect.appendChild(blankOption);

    data.regions.forEach(region => {
      const option = document.createElement('option');
      option.value = region[1];
      option.textContent = region[0];
      regionSelect.appendChild(option);
    });
  })
  .catch(error => {
    console.error('Error sending request:', error);
  });
}
