import { Controller } from "@hotwired/stimulus"
import { driver } from "driver.js"
// TODO: Look into importing CSS via JS in Stimulus controller after CSS import issue is resolved
// Currently included via link tag on page
// import "driver.js/dist/driver.css"

// Connects to data-controller="books-edit-tour"
export default class extends Controller {
  static values = {
    promptsInProgress: Number,
    promptsComplete: Number,
  };

  connect() {
    try {
      if (this.promptsInProgressValue > 0 || this.promptsCompleteValue > 0) {
        return;
      }

      this.startTour();
    }
    catch (error) {
      console.error(error);
    }
  }

  startTour() {
    const driverObj = driver({
      steps: [
        {
          popover: {
            title: 'Welcome to your story!',
            description: 'Here\'s a quick walkthrough to help you get started.'
          }
        },
        {
          element: '#list-of-prompts .card',
          popover: {
            title: 'Questions',
            description: 'You\’ll be answering these as you tell your story. To switch questions, you can just click on the one you want. You can also drag and reorder them.',
            side: "left",
            align: 'start',
          }
        },
        {
          element: '#new-prompt',
          popover: {
            title: 'New questions',
            description: 'You can add, edit, or delete any question in your story. We started you off with some question that we found meaningful, but you manage these however you want.',
            side: "left",
            align: 'start',
            onNextClick: () => {
              this.#hidePrompts()
              driverObj.moveNext()
            },
          }
        },
        {
          element: '#delete-button',
          popover: {
            title: 'Delete question',
            description: 'Which ever question is currently active is the question that will be deleted.',
            side: "left",
            align: 'start',
            onPrevClick: () => {
              this.#showPrompts()
              driverObj.movePrevious()
            },
          }
        },
        {
          element: '#transcription-button',
          popover: {
            title: 'Voice transcriber',
            description: 'You can record your answers using just your voice, without having to type. We\’ll transcribe everything you say and add it to your answer.',
            side: "left",
            align: 'start'
          }
        },
        {
          element: '#ghostwriter-button',
          popover: {
            title: 'AI Editor',
            description: 'If you want to clean up your narrative you can click this button. Our AI editor will create a new version of your content. Your original content will still be saved and restorable.',
            side: "left",
            align: 'start'
          }
        },
        {
          element: '#image-edit-button',
          popover: {
            title: 'Images',
            description: 'You can add an image per question. These will be printed at the top of each question\’s page.',
            side: "left",
            align: 'start'
          }
        },
        {
          element: '#save-button',
          popover: {
            title: 'Save status',
            description: 'If this is white, nothing was saved yet. If it\’s gray, it\’s in progress. Once you click it manually, it\’ll turn green. This means that this question is complete.',
            side: "left",
            align: 'start',
            onNextClick: () => {
              if (window.innerWidth <= 768) {
                driverObj.moveTo(9)
              } else {
                driverObj.moveNext()
              }
            },
          }
        },
        {
          element: '#versions-button',
          popover: {
            title: 'Versions',
            description: 'This is where your versions are saved that are produced every time you use the AI editor.',
            side: "left",
            align: 'start'
          }
        },
        {
          element: '#pdf-preview-button',
          popover: {
            title: 'PDF preview',
            description: 'You can view how your the question and answer will be printed in the book.',
            side: "left",
            align: 'start',
            onPrevClick: () => {
              if (window.innerWidth <= 768) {
                driverObj.moveTo(7)
              } else {
                driverObj.movePrevious()
              }
            },
            onNextClick: () => {
              this.#showPrompts()
              driverObj.moveNext()
            },
          }
        },
        {
          element: '#opal-button',
          popover: {
            title: 'Opal',
            description: 'Opal is your personal AI biographer. You can start a conversation with her here. Grab a cup of coffee, sit back, and have a chat.',
            side: "left",
            align: 'start',
            onPrevClick: () => {
              this.#hidePrompts()
              driverObj.movePrevious()
            },
          }
        },
        {
          element: '#launch-tour-button',
          popover: {
            title: 'That\'s it for now!',
            description: 'If you ever have any questions please reach out to us at any time. You can also relaunch this tour at anytime by clicking this button.'
          }
        },
      ]
    });
    
    driverObj.drive();
  }

  #hidePrompts() {
    // Switch views on mobile
    if (window.innerWidth <= 768) {
      document.getElementById("container-for-prompts").classList.add("d-none");
      document.getElementById("container-for-response").classList.remove("d-none");
    }
  }

  #showPrompts() {
    // Switch views on mobile
    if (window.innerWidth <= 768) {
      document.getElementById("container-for-prompts").classList.remove("d-none");
      document.getElementById("container-for-response").classList.add("d-none");
    }
  }
}
