import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import {
  renderBookContentStatusCheckIcon,
  updatePromptIcons,
} from "../prompt_icons";
import { createAlert } from "../async_alerts";
import JSConfetti from 'js-confetti'
import { Modal } from "bootstrap";

// Connects to data-controller="books"
export default class extends Controller {
  static values = {
    authorCanUpdate: Boolean,
  };
  
  static targets = [
    "bookContentVersionModalCreatedAt",
    "bookContentVersionModalPrompt",
    "bookContentVersionModalResponse",
    "bookContentVersionsList",
    "ghostwriterButton",
    "imageButton",
    "prompt",
    "promptDeleteButton",
    "promptForVoiceRecorder",
    "promptsListButton",
    "response",
    "responseCompleteButton",
    "responseForm",
    "responseTab",
    "savingStateComplete",
    "savingStateFailed",
    "savingStateInProgress",
    "startButton",
    "stopButton",
    "viewBookContentPdfButton",
    "versionsListButton",
  ]

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const contentParam = urlParams.get("content");

    // TODO: Make this nicer
    try {
      var csrfToken = document.querySelector("meta[name='csrf-token']").content
    } catch {
      document.addEventListener('DOMContentLoaded', (event) => {
        var csrfToken = document.querySelector("meta[name='csrf-token']").content
      });
    }

    this.headers = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": csrfToken
    }

    this.json_headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": csrfToken
    }

    if (contentParam) {
      const bookContentListItem = document.getElementById(contentParam);

      bookContentListItem.click();

      setTimeout(() => {
        bookContentListItem.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 1000);
    }

    this.inactivityTimeout = null;
    this.inactivityDelay = 1000;

    if (this.authorCanUpdateValue) {
      this.bookContentVersionModal = new Modal(this.element.querySelector('#bookContentVersionModal'));
    }

    this.promptsList = document.getElementById('list-of-prompts');
    this.sortablePrompts = Sortable.create(this.promptsList, {
      filter: "#new-prompt",
      delay: 1000,
      delayOnTouchOnly: true,
      onEnd: this.reorderBookContents.bind(this),
    });

    this.element.addEventListener('ghostwriter:success', event => {
      this.saveDraftBookContent({ save_with_version: true });
    });
  }

  loadBookContent(event) {
    event.preventDefault();

    const bookContentListItem = event.currentTarget;

    fetch(`/book_contents/${bookContentListItem.id}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        // Switch views on mobile
        if (window.innerWidth <= 768) {
          document.getElementById("container-for-prompts").classList.add("d-none");
          document.getElementById("container-for-response").classList.remove("d-none");
        }

        // Show elements
        document.getElementById("prompt-header").classList.remove("d-none");
        document.getElementById("prompt-text-area").classList.remove("d-none");
        document.getElementById("response-header").classList.remove("d-none");
        document.getElementById("response-text-area").classList.remove("d-none");

        if (data.book_status !== "complete") {
          if (this.authorCanUpdateValue) {
            this.ghostwriterButtonTarget.disabled = false;
            this.imageButtonTarget.disabled = false;
            this.promptDeleteButtonTarget.disabled = false;
            this.responseCompleteButtonTarget.disabled = false;
            this.startButtonTarget.disabled = false;
            this.stopButtonTarget.disabled = false;
            this.versionsListButtonTarget.disabled = false;
            this.viewBookContentPdfButtonTarget.disabled = false;
            this.responseTarget.disabled = false;
          }
        }

        // Update image and status icons
        if (this.authorCanUpdateValue) {
          updatePromptIcons(data);
        }

        // Update versions lists
        if (this.authorCanUpdateValue) {
          this.populateBookContentVersionsList(data.book_content_versions);
        }

        // Update response form
        if (this.authorCanUpdateValue) {
          this.responseFormTarget.action = `/book_contents/${bookContentListItem.id}`;
        }
        this.promptTarget.value = data.book_content.prompt;
        if (this.authorCanUpdateValue) {
          this.promptForVoiceRecorderTarget.innerHTML = data.book_content.prompt;
        }
        this.responseTarget.value = data.book_content.response;

        // Prepare for image editing
        if (this.authorCanUpdateValue) {
          document.getElementById("modal-prompt-text").innerText = data.book_content.prompt;
          document.getElementById("canvas-target").dataset.bookContentId = data.book_content.id;
          if (data.book_content.response !== null) {
            document.getElementById("modal-response-text").innerText = truncate(data.book_content.response, 280);
          }
          if (data.image.url !== null) {
            document.getElementById("canvas-target-image").src = data.image.url;
          } else {
            document.getElementById("canvas-target-image").src = "";
          }
        }

        // Resize textarea to fit content
        autoresize(this.promptTarget);

        // Highlight card as active
        const previousActive = document.getElementsByClassName("card border-dark")[0];
        if (previousActive !== undefined) {
          previousActive.classList.remove("border-dark");
        };
        bookContentListItem.classList.add("border-dark");

        // Update URL
        const url = new URL(window.location.href);
        url.searchParams.set("content", bookContentListItem.id);
        window.history.pushState({}, "", url);
      })
      .catch((error) => {
        createAlert(
          error,
          {
            headerText: "Error",
            iconClass: "bi-exclamation-circle",
            textColorClass: "text-danger"
          }
        );
      });
  }

  populateBookContentVersionsList(versions) {
    while (this.bookContentVersionsListTarget.firstChild) {
      this.bookContentVersionsListTarget.removeChild(this.bookContentVersionsListTarget.firstChild);
    }

    versions.forEach(version => {
      const listItem = document.createElement("li");
      const link = document.createElement("a");
      link.classList.add("dropdown-item");
      link.href = "#";
      link.textContent = formattedDateAndTime(version.created_at);
      link.dataset.versionId = version.id;
      link.dataset.createdAt = formattedDateAndTime(version.created_at);
      link.dataset.prompt = version.attributes.prompt;
      link.dataset.response = version.attributes.response;
      link.addEventListener("click", (event) => this.openBookContentVersionModalWithData(event));

      listItem.appendChild(link);
      this.bookContentVersionsListTarget.appendChild(listItem);
    });
  }

  openBookContentVersionModalWithData(event) {
    event.preventDefault();

    const { versionId, createdAt, prompt, response } = event.target.dataset;

    this.bookContentVersionModalCreatedAtTarget.innerText = createdAt;
    this.bookContentVersionModalPromptTarget.innerHTML = prompt;
    this.bookContentVersionModalResponseTarget.innerHTML = response;

    this.bookContentVersionModal.show();
  }

  returnToPromptsList() {
    if (window.innerWidth <= 768) {
      document.getElementById("container-for-prompts").classList.remove("d-none");
      document.getElementById("container-for-response").classList.add("d-none");
    }
  }

  saveResponseOnInput() {
    autoresize(this.promptTarget);

    clearTimeout(this.inactivityTimeout);

    this.inactivityTimeout = setTimeout(() => {
      this.saveDraftBookContent();
    }, this.inactivityDelay);
  }

  newBookContent(event) {
    event.preventDefault();

    const bookId = document.getElementById("response-form").dataset.bookId;

    fetch(`/book_contents?book_id=${bookId}`, {
      method: "POST",
      body: { book_id: bookId },
      headers: this.headers,
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        insertPrompt(data);
      })
      .catch((error) => {
        createAlert(
          error,
          {
            headerText: "Error",
            iconClass: "bi-exclamation-circle",
            textColorClass: "text-danger"
          }
        );
      });
  };

  saveDraftBookContent(options = {}) {
    const form = this.responseFormTarget;
    const formData = new FormData(form);

    formData.append("status", "in_progress");

    if (options.save_with_version) {
      console.log("if (options.save_with_version) {");

      formData.append("save_with_version", "true");
    }

    toggleResponseButtonsDisabled(true);
    toggleSpinnerFor(this.responseCompleteButtonTarget, "on");
    this.toggleSavingState("in_progress");

    fetch(form.action, {
      method: form.method,
      credentials: "same-origin",
      headers: this.headers,
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        console.log(data);

        updateProgressBar(data);
        updatePromptIcons(data);
        updatePromptListItemText(data);
        if (options.save_with_version) {
          console.log("if (options.save_with_version AFTER AFTER) {");

          this.populateBookContentVersionsList(data.book_content_versions);
        }
        this.toggleSavingState("complete");
      })
      .catch((error) => {
        this.toggleSavingState("failed");

        createAlert(
          error,
          {
            headerText: "Error",
            iconClass: "bi-exclamation-circle",
            textColorClass: "text-danger"
          }
        );
      })
      .finally(() => {
        toggleResponseButtonsDisabled(false);
        toggleSpinnerFor(this.responseCompleteButtonTarget, "off");
      });
  }

  saveCompleteBookContent(options = {}) {
    return new Promise((resolve, reject) => {
      const form = this.responseFormTarget;
      const formData = new FormData(form);

      formData.append("status", "complete");

      toggleResponseButtonsDisabled(true);
      toggleSpinnerFor(this.responseCompleteButtonTarget, "on");
      this.toggleSavingState("in_progress");

      fetch(form.action, {
        method: form.method,
        credentials: "same-origin",
        headers: this.headers,
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          updateProgressBar(data);
          updatePromptIcons(data);
          updatePromptListItemText(data);
          this.toggleSavingState("complete");

          if (options.skipCelebrateAndAlert !== true) {
            celebrate();

            createAlert(
              "Your response it now marked complete. If you continue to edit this response, it'll automatically return to draft.",
              {
                headerText: "Success",
                iconClass: "bi-check-circle-fill",
                textColorClass: "text-success"
              }
            );
          }

          resolve(data);
        })
        .catch((error) => {
          this.toggleSavingState("failed");

          createAlert(
            error,
            {
              headerText: "Error",
              iconClass: "bi-exclamation-circle",
              textColorClass: "text-danger"
            }
          );

          reject(error);
        })
        .finally(() => {
          toggleResponseButtonsDisabled(false);
          toggleSpinnerFor(this.responseCompleteButtonTarget, "off");
        });
    });
  }

  viewBookContentPdf(event) {
    event.preventDefault();

    const pdfPreviewButton = document.getElementById("pdf-preview-button")
    const pdfPreviewButtonIcon = document.getElementById("pdf-preview-icon");
    const spinnerIcon = document.getElementsByClassName("pdf-button-spinner-icon")[0];

    pdfPreviewButton.disabled = true;
    pdfPreviewButtonIcon.classList.add("visually-hidden");
    spinnerIcon.classList.remove("visually-hidden");

    const urlParams = new URLSearchParams(window.location.search);
    const contentParam = urlParams.get("content");

    fetch(`/book_contents/${contentParam}/pdf`, {
      method: "GET",
      headers: this.headers,
      credentials: "same-origin",
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error("Network response was not ok.");
    })
    .then((data) => {
      pdfPreviewButton.disabled = false;
      spinnerIcon.classList.add("visually-hidden");
      pdfPreviewButtonIcon.classList.remove("visually-hidden");

      window.open(`${data.file_url}`, '_blank');
    })
    .catch((error) => {
      this.toggleSavingState("failed");

      createAlert(
        error,
        {
          headerText: "Error",
          iconClass: "bi-exclamation-circle",
          textColorClass: "text-danger"
        }
      );
    })
  };

  destroyBookContent(event) {
    event.preventDefault();

    const confirmation = confirm("Are you sure you want to delete this prompt?");

    if (confirmation == true) {
      const urlParams = new URLSearchParams(window.location.search);
      const contentParam = urlParams.get("content");
      const bookContentListItem = document.getElementById(contentParam);

      fetch(`/book_contents/${bookContentListItem.id}`, {
        method: "DELETE",
        body: { id: bookContentListItem.id },
        headers: this.headers,
        credentials: "same-origin",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Network response was not ok.");
          };
        })
        .then((data) => {
          const nextPrompt = bookContentListItem.nextElementSibling;
          const previousPrompt = bookContentListItem.previousElementSibling;

          if (nextPrompt !== null) {
            nextPrompt.click();
          } else if (previousPrompt !== null) {
            previousPrompt.click();
          }

          bookContentListItem.remove();

          updateProgressBar(data);
        })
        .catch((error) => {
          createAlert(
            error,
            {
              headerText: "Error",
              iconClass: "bi-exclamation-circle",
              textColorClass: "text-danger"
            }
          );
        });
    } else {
      return false;
    };
  }

  reorderBookContents(event) {
    event.preventDefault();

    const bookContentListItem = event.item;
    const currentSortOrder = bookContentListItem.dataset.sortOrder;
    const previousPrompt = bookContentListItem.previousElementSibling;

    const prompts = document.querySelectorAll('.prompt-list-item');
    const bottomId = prompts[prompts.length - 1].id;
    const topId = prompts[0].id;

    if (previousPrompt == null) {
      var sortOrder = 1;
    } else if (previousPrompt.dataset.sortOrder > currentSortOrder) {
      var sortOrder = parseInt(previousPrompt.dataset.sortOrder);
    } else {
      var sortOrder = parseInt(previousPrompt.dataset.sortOrder) + 1;
    }

    if (isNaN(sortOrder) || sortOrder == null) {
      const promptsList = document.getElementById("list-of-prompts");
      promptsList.insertBefore(bookContentListItem, promptsList.children[event.oldDraggableIndex])
      return false
    }

    fetch(`/book_contents/${bookContentListItem.id}/reorder`, {
      method: "PATCH",
      body: JSON.stringify(
        {
          bottom_id: bottomId,
          id: bookContentListItem.id,
          sort_order: sortOrder,
          top_id: topId
        }
      ),
      headers: this.json_headers,
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        };
      })
      .then((data) => {
        const promptOrder = data.prompt_order;

        for (let key in promptOrder) {
          if (promptOrder.hasOwnProperty(key)) {
            document.getElementById(key).setAttribute('data-sort-order', promptOrder[key]);
          }
        }
      })
      .catch((error) => {
        createAlert(error, {headerText: "Error", iconClass: "bi-exclamation-circle", textColorClass: "text-danger"});
      });
  }

  toggleSavingState(status) {
    if (status === "in_progress") {
      this.savingStateInProgressTarget.classList.remove("d-none");
      this.savingStateFailedTarget.classList.add("d-none");
      this.savingStateCompleteTarget.classList.add("d-none");
    }
  
    if (status === "failed") {
      this.savingStateInProgressTarget.classList.add("d-none");
      this.savingStateFailedTarget.classList.remove("d-none");
      this.savingStateCompleteTarget.classList.add("d-none");
    }
  
    if (status === "complete") {
      this.savingStateInProgressTarget.classList.add("d-none");
      this.savingStateFailedTarget.classList.add("d-none");
      this.savingStateCompleteTarget.classList.remove("d-none");
    }
  }
}

function insertPrompt(data) {
  // Create prompt
  const prompt = document.createElement("div");
  prompt.classList.add("prompt-list-item", "card", "shadow-sm", "mb-3", "prompt-list-item");
  prompt.setAttribute("id", data.book_content.id);
  prompt.setAttribute("data-action", "click->books#loadBookContent");
  prompt.setAttribute("data-sort-order", data.book_content.sort_order);
  prompt.setAttribute("aria-current", "true");
  prompt.innerHTML = `
    <div class="card-body d-flex flex-row align-items-center">
      <div class="container-for-prompt-icons me-3">
      ${renderBookContentStatusCheckIcon(data)}
      </div>

      <p class="card-text flex-grow-1">${data.book_content.prompt}</p>
    </div>
  `;

  // Add prompt to DOM
  const listOfPrompts = document.getElementById("list-of-prompts");
  listOfPrompts.appendChild(prompt);

  // Update supporting elements
  updateProgressBar(data);

  // Select new prompt
  // TODO: Remove delay, click (which makes a second call), and use DRYied up code from loadBookContent
  delay(100).then(() => {
    prompt.click();
  });
}

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function truncate(string, limit) {
  if (string.length <= limit) {
    return string;
  }

  return string.slice(0, limit - 3) + "...";
}

function toggleSpinnerFor(buttonElem, status) {
  const spinner = buttonElem.getElementsByClassName("spinner")[0];
  const button = buttonElem.getElementsByClassName("icon-or-text")[0];

  if (status === "on") {
    spinner.classList.remove("visually-hidden");
    button.classList.add("visually-hidden");
  }

  if (status === "off") {
    spinner.classList.add("visually-hidden");
    button.classList.remove("visually-hidden");
  }
}

function toggleResponseButtonsDisabled(status) {
  const responseButtons = document.getElementsByClassName("response-button");

  for (let button of responseButtons) {
    button.disabled = status;
  };
}

function updateProgressBar(data) {
  const progressBarComplete = document.getElementById('progress-bar-complete');
  const progressBarInProgress = document.getElementById('progress-bar-in-progress');

  progressBarComplete.ariaValueNow = data.book_percent_complete;
  progressBarComplete.style.width = `${data.book_percent_complete}%`;
  progressBarInProgress.ariaValueNow = data.book_percent_in_progress;
  progressBarInProgress.style.width = `${data.book_percent_in_progress}%`;
}

function updatePromptListItemText(data) {
  const promptListItem = document.getElementById(data.book_content.id);
  const promptListItemText = promptListItem.getElementsByClassName("card-text")[0];

  promptListItemText.innerText = data.book_content.prompt;
}

function autoresize(textarea) {
  textarea.style.height = '20px';
  textarea.style.height = (textarea.scrollHeight) + 'px';
}

function celebrate() {
  const jsConfetti = new JSConfetti();

  jsConfetti.addConfetti({
    confettiNumber: 42,
    emojis: ['📕', '🥳', '📗', '🎉', '📘', '🎊', '📙']
  });
}

function formattedDateAndTime(isoDateString) {
  const date = new Date(isoDateString);

  const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const optionsTime = { hour: 'numeric', minute: '2-digit', hour12: true };

  const formattedDate = new Intl.DateTimeFormat('en-US', optionsDate).format(date);
  const formattedTime = new Intl.DateTimeFormat('en-US', optionsTime).format(date);

  const [month, day, year] = formattedDate.split("/");
  const [time, period] = formattedTime.split(" ");

  return `${year}-${month}-${day} ${time} ${period}`;
}