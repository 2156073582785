export function updatePromptIcons(data) {
  const statusIconInPromptCard = document.getElementById(data.book_content.id).querySelector(".container-for-prompt-icons");
  const statusIconInPromptNav = document.getElementById("status-complete-button");
  
  statusIconInPromptCard.innerHTML = renderBookContentStatusCheckIcon(data);
  statusIconInPromptNav.className = renderBookContentStatusCheckIconButton(data);
}

export function renderBookContentStatusCheckIcon(data) {
  let statusIcon;

  if (data.book_content.status === "complete") {
    statusIcon = `<i class="check bi bi-check-circle-fill text-success"></i>`;
  } else if (data.book_content.status === "in_progress") {
    statusIcon = `<i class="check bi bi-check-circle-fill text-secondary"></i>`;
  } else {
    statusIcon = `<i class="check bi bi-check-circle text-secondary"></i>`;
  }

  return statusIcon;
};

export function renderBookContentStatusCheckIconButton(data) {
  let statusIcon;

  if (data.book_content.status === "complete") {
    statusIcon = "check bi bi-check-circle-fill button-icon link-success icon-or-text";
  } else if (data.book_content.status === "in_progress") {
    statusIcon = "check bi bi-check-circle-fill button-icon link-secondary icon-or-text";
  } else {
    statusIcon = "check bi bi-check-circle button-icon link-secondary icon-or-text";
  }

  return statusIcon;
};