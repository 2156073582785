import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from '@rails/request.js';
import { createAlert } from "../async_alerts";

// Connects to data-controller="books-readers"
export default class extends Controller {
  static values = {
    bookId: Number,
  };

  removeReader(event) {
    event.preventDefault();

    const participantIcon = event.currentTarget;
    const participantId = participantIcon.dataset.participantId;
    const participantName = participantIcon.dataset.participantName;

    const confirmation = confirm(`Are you sure you want to remove ${participantName}?`);

    if (confirmation == true) {
      const request = new FetchRequest("patch", `/books/${this.bookIdValue}/remove_participant?participant_id=${participantId}`, {
        body: JSON.stringify(
          {
            participant_id: participantId,
          }
        )
      })

      request.perform()
        .then((response) => {
          if (response.ok) {
            return response.response.json();
          } else {
            throw new Error("Network response was not ok.");
          };
        })
        .then((data) => {
          participantIcon.parentElement.remove();
        })
        .catch((error) => {
          createAlert(
            error,
            {
              headerText: "Error",
              iconClass: "bi-exclamation-circle",
              textColorClass: "text-danger"
            }
          );
        });
    } else {
      return false;
    };
  }
}
