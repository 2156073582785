import { Controller } from "@hotwired/stimulus"
import { createAlert } from "../async_alerts";

// Connects to data-controller="clipboard"
export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.copyable-text').forEach(element => {
      element.addEventListener('click', this.copyTextToClipboard.bind(this));
    });
  }

  copyTextToClipboard(event) {
    event.preventDefault();

    const target = event.target.closest('.copyable-text');
    
    if (!target) return;

    navigator.clipboard.writeText(target.textContent).then(() => {
      console.log("Text copied to clipboard!");


      createAlert(
        "Text copied to clipboard!",
        {
          headerText: "Success",
          iconClass: "bi-check-circle-fill",
          textColorClass: "text-success"
        }
      );
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }
}
