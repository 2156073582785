import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.authorButton = document.getElementById("author-button");
    this.authorForm = document.getElementById("author-signup-form-container");
    this.cartProgress = document.getElementById("cart-progress");
    this.productSelections = document.getElementById("product-selection-button-container");
    this.cartProgressContainer = document.getElementById("cart-progress-container");
    this.button1 = document.getElementById("btn-1");
    this.button2 = document.getElementById("btn-2");

    this.authorButton.addEventListener('click', (event) => {
      this.displayAuthorForm();
    });
  }

  displayAuthorForm() {
    this.productSelections.classList.add("d-none");
    this.authorForm.classList.remove("d-none");
    this.cartProgress.style.width = "50%";
    this.cartProgressContainer.setAttribute('aria-valuenow', "50%");
    this.button1.classList.remove("pulse");
    this.button2.classList.add("btn-primary", "pulse");
    this.button2.classList.remove("btn-secondary");
  }
}