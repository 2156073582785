import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

// Connects to data-controller="ghostwriter"
export default class extends Controller {
  static values = {
    authorCanUpdate: Boolean,
  };

  static targets = [
    "responseTab",
    "responseText",
    "submitButton",
  ];

  connect() {
    const csrfToken = document.querySelector("meta[name='csrf-token']").content

    this.headers = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": csrfToken
    }

    if (this.authorCanUpdateValue) {
      this.modal = new Modal(document.getElementById('ghostwriterConfirmationModal'));
    }
  }

  submitText() {
    this.modal.show();
  }

  confirmSubmit() {
    this.toggleSpinner(true);

    const formData = new FormData();
    formData.append("text", this.responseTextTarget.value);

    fetch("/ai/ghostwritings", {
      body: formData,
      credentials: "same-origin",
      headers: this.headers,
      method: "POST",
    })
      .then(
        response => response.json()
      )
      .then(data => {
        console.log(data);

        this.responseTextTarget.value = data.content;

        const customEvent = new CustomEvent('ghostwriter:success', {
          bubbles: true,
          detail: { text: this.responseTextTarget.value }
        });

        this.element.dispatchEvent(customEvent);

        this.modal.hide();
      })
      .catch(
        error => console.error(error)
      )
      .finally(() => {
        this.toggleSpinner(false);
      });
  }

  toggleSpinner(show) {
    const submitText = this.submitButtonTarget.querySelector('span');
    const spinner = this.submitButtonTarget.querySelector('i');

    if (show) {
      submitText.classList.add('d-none');
      spinner.classList.remove('d-none');
    } else {
      submitText.classList.remove('d-none');
      spinner.classList.add('d-none');
    }
  }
}
