import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="opal"
export default class extends Controller {
  static values = {
    author: String,
    bookId: Number,
    soundsAcknowledging: Array,
    soundsEncouraging: Array,
  };

  static targets = [
    "audioVisualizer",
    "nextButton",
    "promptDisplay",
    "startButton",
    "stopButton",
  ];

  connect() {
    const csrfToken = document.querySelector("meta[name='csrf-token']").content

    this.headers = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": csrfToken,
    }

    this.audioInstances = [];
    this.canvasContext = this.audioVisualizerTarget.getContext("2d");
    this.soundIsPlaying = false;
    this.speechEndTimeout = null;
    this.userStoppedSession = false;
    this.inSession = false;
    this.userChoseNextQuestion = false;
    this.currentProbingQuestionCount = 0;

    this.prompt = "";
    this.response = "";

    this.nextCommands = ["opal next question", "opal next"];
    this.stopCommands = ["opal i'm done", "opal finished"];

    this.currentPrompt = null;

    this.#idleAnimation();
    this.#prepareAudioContext();
    this.#setupLocalSpeechRecognition();
    this.#updateButtons({ mainButton: ['start', true], next: false });
    this.#prepareNextPrompt();
  }



  // ******************************************************************************************************************
  // Controls
  // ******************************************************************************************************************
  async start() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Your browser does not support the required media devices API.");

      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      alert("Access to the microphone was denied. To engage with Opal you must allow access to the microphone.");

      return;
    }

    // Check if the user has already finished the book
    if (this.#storyIsComplete()) {
      await this.#sayGoodbye();

      return;
    }

    this.userStoppedSession = false;
    this.#clearAudioInstances();
    this.#updateButtons({ mainButton: ['stop', false], next: false });
    await this.#sayHello();
    await this.#playAudioFile("/processing.wav", { fadeIn: true, fadeOut: true, volume: 0.25 });
    if (this.recognition) {this.recognition.start()};
    this.#updateButtons({ mainButton: ['stop', true], next: true });
    await this.#handleSession();
  }

  stop() {
    this.userStoppedSession = true;
    if (this.recognition) {this.recognition.stop()};
    this.#updateButtons({ mainButton: ['stop', false], next: false });
    this.#stopRecordingResponse();
  }

  next() {
    this.userChoseNextQuestion = true;
    this.#playAcknowledgingSound();
    this.#stopRecordingResponse();
  }

  quit(event) {
    const message = "Are you sure you want to close? Your progress for the current question will be lost.";

    if (this.#shouldConfirmQuit() && !confirm(message)) {
      event.preventDefault();
    }
  }



  // ******************************************************************************************************************
  // Main loop
  // ******************************************************************************************************************
  async #handleSession() {
    try {
      this.inSession = true;

      while (this.#shouldContinueInterviewing()) {
        // Asking base question
        if (this.#shouldAskBaseQuestion()) {
          this.promptDisplayTarget.innerHTML = this.prompt;
          await this.#speakText(this.prompt);
          let audioBlob = await this.#startRecordingResponse();
          let writtenResponse = await this.#writeResponse(audioBlob, this.currentPrompt.id);
          this.response += writtenResponse;
          await this.#saveBookContent("in_progress");
        }

        // Probing
        if (this.#shouldProbe()) {
          let responseSegment = this.response;

          while (this.#shouldContinueProbing()) {
            this.currentProbingQuestionCount += 1;

            let question = await this.#interview(responseSegment);
            this.promptDisplayTarget.innerHTML = question;
            await this.#speakText(question);
            let audioBlob = await this.#startRecordingResponse();
            let writtenResponse = await this.#writeResponse(audioBlob, this.currentPrompt.id);
            this.response += writtenResponse;
            responseSegment = writtenResponse;
            await this.#saveBookContent("in_progress");
          }

          this.userChoseNextQuestion = false;
          this.currentProbingQuestionCount = 0;
        }

        // Completing passage
        if (this.#shouldCompletePassage()) {
          await this.#completePassage();
        }

        // Preparing next prompt
        if (this.#shouldPrepareNextPrompt()) {
          await this.#prepareNextPrompt();
        }
      }

      await this.#sayGoodbye();
      this.#updateButtons({ mainButton: ['start', true], next: false });
    } catch (error) {
      this.#handleError(error, "I'm sorry. I seem to be having issues right now. Please try again later.");
    } finally {
      this.inSession = false;
    }
  }



  // ******************************************************************************************************************
  // Helpers
  // ******************************************************************************************************************
  #handleError(error, userMessage = "An unexpected error occurred. Please try again later.") {
    console.error(error);

    this.#speakText(userMessage);

    alert(userMessage);
  }

  #handleSpeechRecognitionResult(event) {
    if (this.verbalConfirmationTimeout) {
      clearTimeout(this.verbalConfirmationTimeout);
    }

    if (this.nextQuestionTimeout) {
      clearTimeout(this.nextQuestionTimeout);
    }

    let interimTranscript = '';
    let finalTranscript = '';
  
    for (let i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        finalTranscript += event.results[i][0].transcript;
      } else {
        interimTranscript += event.results[i][0].transcript;
      }
    }
  
    console.log("Interim transcript:", interimTranscript);
    console.log("Final transcript:", finalTranscript);

    if (this.#shouldListenForSilence()) {
      const randomNumber = Math.ceil(Math.random() * 10);

      if (randomNumber === 1) {
        this.verbalConfirmationTimeout = setTimeout(() => {
          console.log("Silence detected...");

          if (this.soundIsPlaying === false) {
            this.#playEncouragingSound();
          }
        }, 1000);
      }

      this.nextQuestionTimeout = setTimeout(() => {
        console.log("Silence detected...");

        this.#stopRecordingResponse();
      }, 8000);
    }

    let comparedText = finalTranscript.toLowerCase();
    let isNextCommand = this.nextCommands.some(part => comparedText.includes(part));
    let isStopCommand = this.stopCommands.some(part => comparedText.includes(part));

    if (isNextCommand) {
      this.next();
    } else if (isStopCommand) {
      this.stop();
    }
  }

  #sayHello() {
    return new Promise(async (resolve, reject) => {
      if (this.#shouldMakeFirstIntroduction()) {
        if (this.authorValue) {
          this.promptDisplayTarget.innerHTML = `Hello, ${this.authorValue}! I'm Opal! I'm going to write your biography! Let's get started!`;
        } else {
          this.promptDisplayTarget.innerHTML = `Hello! I'm Opal! I'm going to write your biography! Let's get started!`;
        }

        await this.#speakText(this.promptDisplayTarget.innerHTML);
      } else {
        if (this.authorValue) {
          this.promptDisplayTarget.innerHTML = `Hello, ${this.authorValue}! Welcome back! Let's get started!`;
        } else {
          this.promptDisplayTarget.innerHTML = `Hello! Welcome back! Let's get started!`;
        }

        await this.#speakText(this.promptDisplayTarget.innerHTML);
      }

      resolve();
    });
  }

  #sayGoodbye() {
    return new Promise(async (resolve, reject) => {
      if (this.#storyIsComplete()) {
        this.promptDisplayTarget.innerHTML = "Great job! You've finished your book! It's time to print!";
        await this.#speakText(this.promptDisplayTarget.innerHTML);
        this.promptDisplayTarget.innerHTML = "Please press the \"Quit\" button and head to the publish section!";
        await this.#speakText(this.promptDisplayTarget.innerHTML);
      } else {
        this.promptDisplayTarget.innerHTML = "Great job! I'm looking forward to our next session!";
        await this.#speakText(this.promptDisplayTarget.innerHTML);
        this.promptDisplayTarget.innerHTML = "Please press the \"Start\" button when you're ready to begin again!";
        await this.#speakText(this.promptDisplayTarget.innerHTML);
      }

      resolve();
    });
  }

  #updateButtons(args = { mainButton: ['start', false], next: false }) {
    if (args.mainButton[0] === 'start') {
      this.startButtonTarget.classList.remove("d-none");
      this.startButtonTarget.disabled = !args.mainButton[1];

      this.stopButtonTarget.classList.add("d-none");
      this.stopButtonTarget.disabled = args.mainButton[1];
    } else if (args.mainButton[0] === 'stop') {
      this.stopButtonTarget.classList.remove("d-none");
      this.stopButtonTarget.disabled = !args.mainButton[1];

      this.startButtonTarget.classList.add("d-none");
      this.startButtonTarget.disabled = args.mainButton[1];
    }

    this.nextButtonTarget.disabled = !args.next;
  }

  #playEncouragingSound() {
    if (this.soundsEncouragingValue.length > 0) {
      const randomIndex = Math.floor(Math.random() * this.soundsEncouragingValue.length);
      const randomSoundFile = this.soundsEncouragingValue[randomIndex];
      const randomSoundPath = `/sounds_encouraging/${randomSoundFile}`;

      console.log(`Playing encouraging sound: ${randomSoundPath}`);

      this.#playAudioFile(randomSoundPath, { volume: 0.75 }).then(() => {
        console.log("Random encouraging sound played successfully.");
      }).catch((error) => {
        console.error("Error playing random encouraging sound:", error);
      });
    }
  }

  #playAcknowledgingSound() {
    if (this.soundsAcknowledgingValue.length > 0) {
      const randomIndex = Math.floor(Math.random() * this.soundsAcknowledgingValue.length);
      const randomSoundFile = this.soundsAcknowledgingValue[randomIndex];
      const randomSoundPath = `/sounds_acknowledging/${randomSoundFile}`;
      
      console.log(`Playing acknowledging sound: ${randomSoundPath}`);

      this.#playAudioFile(randomSoundPath, { volume: 0.75 }).then(() => {
        console.log("Random affirmation sound played successfully.");
      }).catch((error) => {
        console.error("Error playing random affirmation sound:", error);
      });
    }
  }

  #shouldConfirmQuit() {
    if (this.inSession) {
      return true;
    } else {
      return false;
    }
  }

  #shouldMakeFirstIntroduction() {
    return !this.#storyIsComplete() && parseInt(this.currentPrompt.sort_order, 10) === 1 && !/\S/.test(this.response);
  }

  #shouldContinueInterviewing() {
    return !this.userStoppedSession && !this.#storyIsComplete();
  }

  #shouldAskBaseQuestion() {
    return /\S/.test(this.response) === false;
  }

  #shouldProbe() {
    return !this.userStoppedSession && /\S/.test(this.response) && this.#wordCount(this.response) < 500;
  }

  #shouldContinueProbing() {
    return !this.userStoppedSession && this.#wordCount(this.response) < 500 && !this.userChoseNextQuestion && this.currentProbingQuestionCount < 3;
  }

  #shouldCompletePassage() {
    return /\S/.test(this.response) && this.#wordCount(this.response) >= 1;
  }

  #shouldPrepareNextPrompt() {
    return !this.userStoppedSession;
  }

  #shouldListenForSilence() {
    return this.soundIsPlaying === false;
  }

  #storyIsComplete() {
    return this.currentPrompt === null;
  }

  #wordCount(text) {
    if (!text) return 0;

    const matches = text.match(/\b(\w+)\b/g);

    return matches ? matches.length : 0;
  }

  #startRecordingResponse() {
    return new Promise((resolve, reject) => {
      cancelAnimationFrame(this.idleAnimationFrameRequest);

      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.stream = stream;
          this.#setupMediaRecorder(stream);

          const source = this.audioContext.createMediaStreamSource(stream);
          source.connect(this.analyser);
          this.#drawVisualizer();

          this.mediaRecorder.start();

          this.mediaRecorder.addEventListener('stop', () => {
            const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });

            resolve(audioBlob);
          });
        })
        .catch(error => {
          console.error("Error accessing the microphone:", error);

          reject(error);
        });
    });
  }

  #stopRecordingResponse() {    
    console.log("Stopping recording...");

    this.mediaRecorder.stop();
    this.stream.getTracks().forEach(track => track.stop());

    cancelAnimationFrame(this.animationFrameRequest);
    this.#idleAnimation();
  }

  #playAudioFile(audio_file_path, { fadeIn = false, fadeOut = false, volume = 1 } = {}) {
    this.soundIsPlaying = true;

    return new Promise((resolve, reject) => {
      const audio = new Audio(audio_file_path);
      audio.volume = fadeIn ? 0 : volume;
  
      this.audioInstances.push(audio);
  
      audio.play()
        .then(() => {
          const maxVolume = volume;
          const fadeInDuration = 2000;
          const fadeOutDuration = 5000;
          const fadeInterval = 100;
    
          if (fadeIn) {
            let increment = maxVolume / (fadeInDuration / fadeInterval);

            let fadeAudioIn = setInterval(() => {
              if (audio.volume < maxVolume) {
                audio.volume += increment;
              } else {
                clearInterval(fadeAudioIn);
              }
            }, fadeInterval);
          }
    
          if (fadeOut) {
            setTimeout(() => {
              let decrement = maxVolume / (fadeOutDuration / fadeInterval);

              let fadeAudioOut = setInterval(() => {
                if (audio.volume - decrement > 0) {
                  audio.volume -= decrement;
                } else {
                  clearInterval(fadeAudioOut);
                  audio.volume = 0;
                }
              }, fadeInterval);
            }, (audio.duration * 1000) - fadeOutDuration);
          }
    
          audio.onended = () => {
            const index = this.audioInstances.indexOf(audio);

            if (index > -1) {
              this.audioInstances.splice(index, 1);
            }

            resolve(audio);
          };
        })
        .catch(error => {
          console.error("Error playing the sound:", error);

          reject(error);
        })
        .finally(() => {
          this.soundIsPlaying = false;
        });
    });
  }

  #clearAudioInstances() {
    this.audioInstances.forEach(audio => {
      if (!audio.paused) {
        audio.pause();
        audio.currentTime = 0;
      }
    });

    this.audioInstances = [];
  }



  // ******************************************************************************************************************
  // API calls
  // ******************************************************************************************************************
  #prepareNextPrompt(attempts = 0) {
    return new Promise((resolve, reject) => {
      let url;

      if (this.currentPrompt) {
        url = `/books/${this.bookIdValue}/next_prompt?exclude_id=${this.currentPrompt.id}`;
      } else {
        url = `/books/${this.bookIdValue}/next_prompt`;
      }

      fetch(url, {
        credentials: "same-origin",
        headers: this.headers,
        method: "GET",
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }

          return response.json();
        })
        .then(data => {
          console.log("Next prompt:", data);

          if (data) {
            this.currentPrompt = data;
            this.prompt = data.prompt;
            this.response = data.response;
          } else {
            this.currentPrompt = null;
            this.prompt = "";
            this.response = "";
          }

          resolve(data);
        })
        .catch(error => {
          if (attempts < 3) {
            console.log(`Attempt ${attempts}: retrying...`);

            this.#prepareNextPrompt(attempts + 1);
          } else {
            this.handleError(error, "We're having trouble processing your request. Please try again later.");
          }

          reject(error);
        });
    });
  }

  #speakText(text, attempts = 0) {
    this.soundIsPlaying = true;

    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("text", text);

      fetch('/ai/speeches', {
        body: formData,
        credentials: "same-origin",
        headers: this.headers,
        method: 'POST',
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          return response.blob();
        })
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const audio = new Audio(url);

          this.audioInstances.push(audio);

          console.log("Spoken text:", audio);

          audio.onended = () => {
            const index = this.audioInstances.indexOf(audio);

            if (index > -1) {
              this.audioInstances.splice(index, 1);
            }
            resolve(audio);
          };

          audio.onerror = (e) => {
            reject(new Error("Audio playback failed."));
          };

          // Uncomment below to automatically download the audio file.
          // This is used to grab verbal affirmations from the API.
          // const a = document.createElement("a");
          // document.body.appendChild(a);
          // a.style = "display: none";
          // a.href = url;
          // a.download = "audio.mp3";
          // a.click();
          // document.body.removeChild(a);

          audio.play();
        })
        .catch(error => {
          if (attempts < 3) {
            console.log(`Attempt ${attempts}: retrying...`);

            this.#speakText(text, attempts + 1);
          } else {
            this.handleError(error, "We're having trouble processing your request. Please try again later.");
          }

          reject(error);
        })
        .finally(() => {
          this.soundIsPlaying = false;
        });
    });
  }

  #writeResponse(audioBlob, bookContentId = null, attempts = 0) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("audio", audioBlob);
      formData.append("book_content_id", bookContentId);

      fetch("/ai/transcriptions", {
        body: formData,
        credentials: "same-origin",
        headers: this.headers,
        method: "POST",
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          return response.json();
        })
        .then(data => {
          console.log("Transcription:", data);

          resolve(data.transcription.text);
        })
        .catch(error => {
          if (attempts < 3) {
            console.log(`Attempt ${attempts}: retrying...`);

            this.#writeResponse(text, bookContentId, attempts + 1);
          } else {
            this.handleError(error, "We're having trouble processing your request. Please try again later.");
          }

          reject(error);
        });
    });
  }

  #saveBookContent(status, options = {}, attempts = 0) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("status", status);
      formData.append("prompt", this.prompt);
      formData.append("response", this.response);

      if (options.save_with_version) {
        formData.append("save_with_version", "true");
      }

      fetch(`/book_contents/${this.currentPrompt.id}`, {
        body: formData,
        credentials: "same-origin",
        headers: this.headers,
        method: "PATCH",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          console.log("Saved draft?", data);

          resolve(data);
        })
        .catch(error => {
          if (attempts < 3) {
            console.log(`Attempt ${attempts}: retrying...`);

            this.#saveBookContent(status, options, attempts + 1);
          } else {
            this.handleError(error, "We're having trouble processing your request. Please try again later.");
          }

          reject(error);
        });
      });
  }

  #completePassage(attempts = 0) {
    return new Promise((resolve, reject) => {
      fetch(`/book_contents/${this.currentPrompt.id}/complete_opal_interviewed_passage`, {
        credentials: "same-origin",
        headers: this.headers,
        method: "PATCH",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          console.log("Complete passage request made successfully:", data);

          resolve(data);
        })
        .catch(error => {
          if (attempts < 3) {
            console.log(`Attempt ${attempts}: retrying...`);

            this.#saveBookContent(attempts + 1);
          } else {
            this.handleError(error, "We're having trouble processing your request. Please try again later.");
          }

          reject(error);
        });
      });
  }

  #interview(text, attempts = 0) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("text", text);

      fetch("/ai/interviews", {
        body: formData,
        credentials: "same-origin",
        headers: this.headers,
        method: "POST",
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          return response.json();
        })
        .then(data => {
          console.log("Interview Question:", data);

          resolve(data.content);
        })
        .catch(error => {
          if (attempts < 3) {
            console.log(`Attempt ${attempts}: retrying...`);

            this.#interview(text, attempts + 1);
          } else {
            this.handleError(error, "We're having trouble processing your request. Please try again later.");
          }

          reject(error);
        });
    });
  }



  // ******************************************************************************************************************
  // Initializations
  // ******************************************************************************************************************
  #handleSpeechRecognitionEnd() {
    console.log("Speech recognition ended");
  }

  #setupMediaRecorder(stream) {
    this.mediaRecorder = new MediaRecorder(stream);
    this.audioChunks = [];

    this.mediaRecorder.addEventListener("dataavailable", event => {
      this.audioChunks.push(event.data);
    });
  }

  #setupLocalSpeechRecognition() {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.lang = 'en-US';
      this.recognition.interimResults = true;

      this.recognition.onresult = event => this.#handleSpeechRecognitionResult(event);
      this.recognition.onend = () => {
        console.log("Speech recognition service disconnected. Attempting to restart...");

        this.#handleSpeechRecognitionEnd();
        this.recognition.start();
      };
    } else {
      console.error("Speech Recognition API not supported in this browser.");
    }
  }

  #prepareAudioContext() {
    if (!this.audioContext) {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = 2048;
      this.bufferLength = this.analyser.frequencyBinCount;
      this.dataArray = new Uint8Array(this.bufferLength);
    }
  }

  #idleAnimation() {
    const width = this.audioVisualizerTarget.width;
    const height = this.audioVisualizerTarget.height;
    const centerY = height / 2;
    const amplitude = 0;
  
    this.canvasContext.clearRect(0, 0, width, height);
    this.canvasContext.strokeStyle = 'rgb(255, 255, 255)';
    this.canvasContext.lineWidth = 1;
  
    this.canvasContext.beginPath();
    this.canvasContext.moveTo(0, centerY);
  
    for (let i = 0; i < width; i++) {
      const y = centerY + amplitude * Math.sin(i * 0.01);
      this.canvasContext.lineTo(i, y);
    }
  
    this.canvasContext.stroke();
  
    this.idleAnimationFrameRequest = requestAnimationFrame(() => this.#idleAnimation());
  }

  #drawVisualizer() {
    requestAnimationFrame(() => this.#drawVisualizer());

    this.canvasContext.clearRect(0, 0, this.audioVisualizerTarget.width, this.audioVisualizerTarget.height);

    this.analyser.getByteTimeDomainData(this.dataArray);

    this.canvasContext.lineWidth = 1;
    this.canvasContext.strokeStyle = 'rgb(255, 255, 255)';

    this.canvasContext.beginPath();

    var sliceWidth = this.audioVisualizerTarget.width * 1.0 / this.bufferLength;
    var x = 0;

    for(var i = 0; i < this.bufferLength; i++) {
   
      var v = this.dataArray[i] / 128.0;
      var y = v * this.audioVisualizerTarget.height/2;

      if(i === 0) {
        this.canvasContext.moveTo(x, y);
      } else {
        this.canvasContext.lineTo(x, y);
      }

      x += sliceWidth;
    }

    this.canvasContext.lineTo(this.audioVisualizerTarget.width, this.audioVisualizerTarget.height/2);
    this.canvasContext.stroke();
  }
}
