import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="books-new"
export default class extends Controller {
  handleRoleSelection(event) {
    const authorInvitesContainer = document.getElementById('container-for-author-invites');
    const sponsorInvitesContainer = document.getElementById('container-for-sponsor-invites');
    const messageForAuthor = document.getElementById('message-for-author');
    const messageForSponsor = document.getElementById('message-for-sponsor');

    // Clear existing values in invite inputs when switching roles
    const inputsForInvites = document.getElementsByClassName("input-for-invites");
    for (let input of inputsForInvites) {
      input.value = "";
    };

    if (event.params.role === "author") {
      authorInvitesContainer.classList.add("visually-hidden");
      sponsorInvitesContainer.classList.remove("visually-hidden");
      messageForAuthor.classList.remove("visually-hidden");
      messageForSponsor.classList.add("visually-hidden");
    } else {
      authorInvitesContainer.classList.remove("visually-hidden");
      messageForAuthor.classList.add("visually-hidden");
      messageForSponsor.classList.remove("visually-hidden");
    }
  }

  handleTemplateSelection(event) {
    const allTemplateCovers = document.getElementsByClassName("template-front-cover");

    for (let templateCover of allTemplateCovers) {
      templateCover.style.opacity = "0.5";
    }

    const selectedTemplateCover = event.currentTarget.querySelector(".template-front-cover");

    selectedTemplateCover.style.opacity = "1.0";
  }
}
